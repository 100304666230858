import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

const changeSubscription = async (packageType) => {
  try {
    const response = await axios.post(`${API_URL}/dashboard/subscriptions`, {
      packageType,
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const api = {};
api.changeSubscription = changeSubscription;

export default api;
