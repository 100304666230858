<template>
  <section id="plans">

    <div class="page">
      <app-tabs :tabs="[
        { label: 'Plans', route: { name: 'plans' } },
        { label: 'Invoices', route: { name: 'invoices' } },
        { label: 'Cards', route: { name: 'cards' } }]"
      />

      <p class="presentation" v-if="me.package">
        <slot v-if="me.package.type === PackageTypes.FREE">Your current plan is <strong>{{ PackageTypesLabel.FREE }}</strong>. Need more? Just choose the premium plan, the change will be immediate.</slot>
        <slot v-else-if="me.package.type === PackageTypes.CUSTOM">
          Your current plan is <strong>{{ PackageTypesLabel.CUSTOM }}</strong>.
          Contact us if you wish to update or downgrade your plan.
        </slot>
        <slot v-else>
          Your current plan is <strong>{{ PackageTypesLabel[me.package.type] }}</strong>.
          <slot v-if="me.willDowngradeAt">Your request for the <strong>{{ PackageTypesLabel[me.willDowngradeTo] }}</strong> will be active on <strong>{{ $dayjs(me.willDowngradeAt).format('MMMM D, YYYY') }}</strong></slot>
          <slot v-else>You can downgrade now, but the changes will take effect early next month.</slot>
        </slot>
        <slot v-if="!me.apiKey">
          <br/> Please <router-link :to="{ name: 'card' }">add a credit card</router-link> to upgrade your plan.
        </slot>
        <slot>
          <br/>
          <br/>
          ☝️ If you are using a script blocker or adblocker, it can sometimes block your bank 3D Secure validation pop in. If your upgrade did not go through, please contact us on chat or email for assistance.
        </slot>
      </p>

      <section class="prices" v-if="me.package">
        <div class="grid-container">
          <div class="prices-list">
            <div class="price-item-container cell medium-3 auto">
              <div class="price-item" :class="{ secondary: me.package.type === PackageTypes.FREE }">
                <div class="price-item-name">
                  <div>
                    <h3>Basic Plan</h3>
                    <p v-if="me.package.type === PackageTypes.FREE" class="price-item-current">Current plan</p>
                  </div>
                  <div>
                    <p class="price-item-amount">Free</p>
                  </div>
                </div>
                <ul class="price-item-advantages">
                  <li>Up to 400 docs / month</li>
                  <li>HDS</li>
                  <li>1 template</li>
                </ul>
                <app-button :disabled="isLoadingPlan" v-if="![PackageTypes.CUSTOM, PackageTypes.FREE].includes(me.package.type)" @click="changeSubscription(PackageTypes.FREE)" :loading="isLoading.FREE" look="secondarySquare">Change Plan</app-button>
                <app-button v-if="me.package.type === PackageTypes.CUSTOM" @click="requestDowngrade(PackageTypes.FREE)" look="secondarySquare">Contact us</app-button>
              </div>
            </div>

            <div class="price-item-container cell medium-3 auto">
              <div class="price-item" :class="{ secondary: me.package.type === PackageTypes.PREMIUM }">
                <div class="price-item-name">
                  <div>
                    <h3>Premium</h3>
                    <p v-if="me.package.type === PackageTypes.PREMIUM" class="price-item-current">Current plan</p>
                  </div>
                  <div>
                    <p class="price-item-amount">14,90<small>€</small></p>
                    <p class="price-item-period">/month</p>
                  </div>
                </div>
                <ul class="price-item-advantages">
                  <li>Up to 4000 docs / month</li>
                  <li>HDS</li>
                  <li>1 template</li>
                  <li>Asynchronous renders</li>
                  <li>Medium rate limit</li>
                  <li>Delegate upload (presigned URLs)</li>
                </ul>
                  <app-button :disabled="isLoadingPlan" v-if="![PackageTypes.PREMIUM, PackageTypes.CUSTOM].includes(me.package.type) && me.apiKey" @click="changeSubscription(PackageTypes.PREMIUM)" :loading="isLoading.PREMIUM" look="secondarySquare">Change Plan</app-button>
                  <app-button v-if="me.package.type === PackageTypes.CUSTOM" @click="requestDowngrade(PackageTypes.PREMIUM)" look="secondarySquare">Contact us</app-button>
                </div>
            </div>

            <div class="price-item-container cell medium-3 auto">
              <div class="price-item" :class="{ secondary: me.package.type === PackageTypes.PREMIUM_PLUS }">
                <div class="price-item-name">
                  <div>
                    <h3>Premium +</h3>
                    <p v-if="me.package.type === PackageTypes.PREMIUM_PLUS" class="price-item-current">Current plan</p>
                  </div>
                  <div>
                    <p class="price-item-amount">44,50<small>€</small></p>
                    <p class="price-item-period">/month</p>
                  </div>
                </div>
                <ul class="price-item-advantages">
                  <li>Up to 12000 docs / month</li>
                  <li>HDS</li>
                  <li>10 templates</li>
                  <li>Asynchronous renders</li>
                  <li>High rate limit</li>
                  <li>Delegate upload (presigned URLs)</li>
                </ul>
                  <app-button :disabled="isLoadingPlan" v-if="![PackageTypes.PREMIUM_PLUS, PackageTypes.CUSTOM].includes(me.package.type) && me.apiKey" @click="changeSubscription(PackageTypes.PREMIUM_PLUS)" :loading="isLoading.PREMIUM_PLUS" look="secondarySquare">Change Plan</app-button>
                  <app-button v-if="me.package.type === PackageTypes.CUSTOM" @click="requestDowngrade(PackageTypes.PREMIUM_PLUS)" look="secondarySquare">Contact us</app-button>
                </div>
            </div>

            <div class="price-item-container cell medium-3 auto">
              <div class="price-item" :class="{ secondary: me.package.type === PackageTypes.PRO }">
                <div class="price-item-name">
                  <div>
                    <h3>Pro</h3>
                    <p v-if="me.package.type === PackageTypes.PRO" class="price-item-current">Current plan</p>
                  </div>
                  <div>
                    <p class="price-item-amount">119,00<small>€</small></p>
                    <p class="price-item-period">/month</p>
                  </div>
                </div>
                <ul class="price-item-advantages">
                  <li>Up to 36000 docs / month, then 0,0034€ / each additional doc</li>
                  <li>HDS</li>
                  <li>10 templates</li>
                  <li>Asynchronous renders</li>
                  <li>Higher rate limit</li>
                  <li>Delegate upload (presigned URLs)</li>
                </ul>
                  <app-button :disabled="isLoadingPlan" v-if="![PackageTypes.PRO, PackageTypes.CUSTOM].includes(me.package.type) && me.apiKey" @click="changeSubscription(PackageTypes.PRO)" :loading="isLoading.PRO" look="secondarySquare">Change Plan</app-button>
                  <app-button v-if="me.package.type === PackageTypes.CUSTOM" @click="requestDowngrade(PackageTypes.PRO)" look="secondarySquare">Contact us</app-button>
                </div>
            </div>

            <div class="price-item-container cell medium-3 auto">
              <div class="price-item" :class="{ secondary: me.package.type === PackageTypes.CUSTOM }">
                <div class="price-item-name">
                  <h3>Custom Plan 🛠</h3>
                </div>
                <template v-if="me.package.type === PackageTypes.CUSTOM">
                  <ul class="price-item-advantages">
                    <li>{{ me.package.monthlyLimit }} documents per month</li>
                    <li>{{ me.package.rateLimit }} requests per minute</li>
                    <li v-if="me.package.isAsyncAllowed">Asynchronous renders</li>
                  </ul>
                  <app-button @click="updateCrazyPlan" look="tertiary">Update Plan</app-button>
                </template>
                <template v-else>
                  <ul class="price-item-advantages">
                    <li>Everything from lower plans</li>
                    <li>Unlimited templates</li>
                    <li>Custom amount of documents</li>
                    <li>Custom rate limit</li>
                    <li>Multi or Single-tenant</li>
                    <li>Cheaper price per document</li>
                    <li>Top priority technical support</li>
                  </ul>
                  <app-button v-if="me.apiKey" @click="requestCrazyPlan" look="secondarySquare">Contact Us</app-button>
                </template>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import userApi from '@/services/api/user';
import subscriptionApi from '@/services/api/subscription';
import PackageTypes from '@/enums/packageTypes';
import PackageTypesLabel from '@/enums/packageTypesLabel';

export default {
  name: 'plans',

  data() {
    return {
      isLoading: {
        FREE: false,
        PREMIUM: false,
        PREMIUM_PLUS: false,
        PRO: false,
      },
      me: {},
      PackageTypes,
      PackageTypesLabel,
    };
  },
  computed: {
    isLoadingPlan() {
      return Object.values(this.isLoading).some((isLoading) => isLoading);
    },
  },

  mounted() {
    this.getMe();
  },

  methods: {
    async getMe() {
      try {
        const me = await userApi.getMe();
        if (me) {
          this.me = me;
        }
      } catch (er) {
        this.$message.show({
          title: 'Error',
          text: 'Impossible to find user',
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
    },
    openChatbox(message) {
      if (this.me.mail) {
        window.$crisp.push(['set', 'user:email', [this.me.email]]);
      }
      window.$crisp.push(['set', 'message:text', [message]]);
      window.$crisp.push(['do', 'chat:open']);
    },
    updateCrazyPlan() {
      this.openChatbox('Hello, I am interested in updating my custom plan for Doppio, specifically to have ........\nHow could we make that possible ?');
    },
    requestCrazyPlan() {
      this.openChatbox('Hello, I am interested in subscribing to a custom plan for Doppio, specifically to have ........\nHow could we make that possible ?');
    },
    requestDowngrade(packageType) {
      this.openChatbox(`Hello, I would like to unsubscribe to my custom plan and fall back to ${PackageTypesLabel[packageType]} instead.\nHow could we make that possible ?`);
    },
    async changeSubscription(subscription) {
      this.isLoading[subscription] = true;
      try {
        const subscriptionResponse = await subscriptionApi.changeSubscription(subscription);
        switch (subscriptionResponse.status || '') {
          // Cas payment en attente d'action utilisateur
          case 'PENDING': {
            this.$message.show({
              title: 'Payment action required',
              text: 'Your request has been accepted, but the payment did not go through. Please check your email for instructions on how to proceed with the payment via the Stripe platform.',
              confirmText: 'Ok',
              hasCancel: false,
            });
            break;
          }

          // Cas: abonnement réussi
          case 'SUCCESS':
          default:
            this.$message.show({
              title: 'Change request successfull !',
              text: subscriptionResponse.isDowngrade
                ? `Your request to subscribe to the ${PackageTypesLabel[subscription]} has been duly noted. The change will take effect at the end of the curent billing cycle.`
                : `Your request to subscribe to the ${PackageTypesLabel[subscription]} has been successfull! Please enjoy your upgraded plan.`,
              confirmText: 'Ok',
              hasCancel: false,
            });
        }
        await this.getMe();
      } catch (err) {
        let text = 'An unexpected error has occured. Please try again later and contact us if the issue persists.';
        if (err.response.status === 403 && err.response.data.message.includes('default payment')) {
          text = 'Your account is in default payment. Please pay your bills before changing your subscription plan.';
        } else if (err.response.status === 403 && err.response.data.message.includes('default credit card')) {
          text = 'Your account does not have a credit card. Please add a credit card to change your subscription.';
        } else if (err.response.status === 403 && err.response.data.message.includes('configured credit card')) {
          text = 'Your account does not have a configured credit card. Please delete and re-add your current credit card in order to accept payments.';
        } else if (err.response.status === 402) {
          text = 'The payment did not go through. Please add a valid default credit card before upgrading your subscription.';
        }
        this.$message.show({
          text,
          title: 'Error',
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
      this.isLoading[subscription] = false;
    },
  },
};
</script>
<style lang="sass">
#plans

  .page

    .presentation
      font-family: 'Inter', sans-serif
      margin-top: 30px
      a
        font-family: 'Inter', sans-serif
        color: $accent100
        text-decoration: underline

    p
      font-weight: 400
      font-size: 14px
      line-height: 20px
      color: $primary20
      strong
        color: white
        font-weight: initial

    @include page

    h1
      color: $white
      margin-bottom: 30px

      svg
        margin-right: 10px

    .prices
      color: white
      .grid-container
        padding: 0

        .prices-list
          padding: 0
          grid-gap: 13px
          margin-top: 40px
          width: 100%
          display: flex
          flex-wrap: wrap
          gap: 13px
          align-items: stretch
          justify-content: center

          .price-item-container
            box-sizing: border-box
            max-width: 50%
            flex-grow: 1

            .price-item
              background: $primary80
              box-sizing: border-box
              border: 1px solid
              border-image-source: linear-gradient(90deg, rgba(106, 100, 130, 0.5) 21.99%, rgba(106, 100, 130, 0) 100%)
              border-radius: 8px
              padding: 25px 20px
              width: 100%
              transition: all ease-in-out 0.5s
              display: flex
              flex-direction: column

              .price-item-name
                display: flex
                justify-content: space-between
                align-items: flex-start
                min-height: 70px

                h3
                  font-size: 17px
                  font-weight: 700
                  line-height: 25px
                  margin-bottom: 5px

                .price-item-current
                  display: inline-flex
                  margin: 0 0 0 -2px
                  padding: 6px 10px
                  color: $white
                  background: rgba(255, 255, 255, 0.2)
                  border-radius: 16px
                  gap: 10px
                  font-weight: 400
                  font-size: 11px
                  line-height: 14px

                .price-item-amount
                  font-size: 22px
                  line-height: 30px
                  font-weight: 700
                  color: $white

                .price-item-period
                  font-size: 10px
                  font-weight: 400
                  line-height: 12px
                  color: $primary20
                  text-align: right

              .price-item-advantages
                list-style: none
                margin-top: 10px
                padding: 0
                flex-grow: 2

                li
                  position: relative
                  margin-left: 20px
                  font-family: 'Inter', sans-serif
                  font-size: 13px
                  line-height: 1.4
                  font-weight: 400

                  &::before
                    position: absolute
                    top: 2px
                    left: -20px
                    width: 10px
                    height: 10px
                    content: url(~@/assets/img/list-style-light.svg)
                    display: inline-block
                    vertical-align: middle
                    margin-right: 6px
                    margin-left: -2px

                  &:not(:last-child)
                    margin-bottom: 18px

              .price-item-cta
                display: block
                margin-top: 40px
                width: 100%

                .app-button
                  width: 100%
                  border-radius: 100px

              &.secondary
                background: $primary
                border: none

                li
                  &::before
                    content: url(~@/assets/img/list-style-accent.svg)

                .price-item-cta

                  .app-button
                    border: 1px solid $white

              .app-button
                width: 100%
                margin-top: 25px

      @media (max-width: 767px)
        padding: 98px 24px

        .grid-container

          h2
            font-size: 25px

          .prices-list
            margin-top: 24px
            width: 100%

            .price-item-container
              box-sizing: border-box
              padding: 32px 0

              .price-item

                .price-item-name

                  h3
                    font-size: 22px

                .price-item-cta
                  margin-top: 24px
</style>
